import React from 'react'
import ageingVideo from './images/ageing.mp4'
import avaratifyVideo from './images/avaratify.mp4'
import babyfyVideo from './images/babyfy.mp4'
import cartoonifyVideo from './images/cartoonify.mp4'

const videoNames = {
  AGEING: 'AGEING',
  AVATARIFY: 'AVATARIFY',
  BABIFY: 'BABIFY',
  CARTOONIFY: 'CARTOONIFY',
}

const FeaturesVideos = ({ selectedStyle, setSelectedStyle }) => {
  return (
    <div className='features-videos'>
      <div
        onClick={() => setSelectedStyle(videoNames.AVATARIFY)}
        className={
          'features-videos__wrapper' +
          (selectedStyle === videoNames.AVATARIFY
            ? ' features-videos__wrapper--active'
            : '')
        }
      >
        <video
          className='features-videos__video'
          src={avaratifyVideo}
          type='video/mp4'
          loop
          autoPlay
          playsInline
          muted
        />
      </div>
      <div
        onClick={() => setSelectedStyle(videoNames.CARTOONIFY)}
        className={
          'features-videos__wrapper' +
          (selectedStyle === videoNames.CARTOONIFY
            ? ' features-videos__wrapper--active'
            : '')
        }
      >
        <video
          className='features-videos__video'
          src={cartoonifyVideo}
          type='video/mp4'
          loop
          autoPlay
          playsInline
          muted
        />
      </div>
      <div
        onClick={() => setSelectedStyle(videoNames.BABIFY)}
        className={
          'features-videos__wrapper' +
          (selectedStyle === videoNames.BABIFY
            ? ' features-videos__wrapper--active'
            : '')
        }
      >
        <video
          className='features-videos__video'
          src={babyfyVideo}
          type='video/mp4'
          loop
          autoPlay
          playsInline
          muted
        />
      </div>
      <div
        onClick={() => setSelectedStyle(videoNames.AGEING)}
        className={
          'features-videos__wrapper' +
          (selectedStyle === videoNames.AGEING
            ? ' features-videos__wrapper--active'
            : '')
        }
      >
        <video
          className='features-videos__video'
          src={ageingVideo}
          type='video/mp4'
          loop
          autoPlay
          playsInline
          muted
        />
      </div>
    </div>
  )
}

export default FeaturesVideos
