import React, { useState } from 'react'

const Account = () => {
  const [isEmailValid, setIsEmailValid] = useState(false)

  const updateEmailValidity = (event) => {
    if (event.target.validity.valid) {
      setIsEmailValid(true)
    } else {
      setIsEmailValid(false)
    }
  }

  return (
    <main className='account'>
      <h2 className='account__title'>My Account</h2>
      <p className='account__text'>
        Enter Your email which you used on this website
      </p>
      <input
        onChange={updateEmailValidity}
        className='account__input'
        type='email'
        placeholder='somepost@gmail.com'
      />
      <button disabled={!isEmailValid} className='account__button'>
        Continue
      </button>
      <a className='account__link' href='mailto:support@saucy.art'>
        Have You Forgotten Email?
      </a>
    </main>
  )
}

export default Account
