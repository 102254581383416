import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FeaturesVideos from '../../components/FeaturesVideos/FeaturesVideos'

const FeaturesPage = () => {
  const [selectedStyle, setSelectedStyle] = useState('')

  return (
    <main className='features-page'>
      <h2 className='features-page__title'>Choose a preferable style</h2>
      <FeaturesVideos
        selectedStyle={selectedStyle}
        setSelectedStyle={setSelectedStyle}
      />
      <Link
        to='/processing'
        disabled={!selectedStyle}
        className={
          'features-page__button' +
          (!selectedStyle ? ' features-page__button--disabled' : '')
        }
      >
        Next
      </Link>
    </main>
  )
}

export default FeaturesPage
