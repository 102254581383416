import MainPage from '../pages/MainPage/MainPage'
import { Route, Routes, useLocation } from 'react-router-dom'
import CreatingAccountPage from '../pages/CreatingAccountPage/CreatingAccountPage'
import Header from './Header/Header'
import Account from '../pages/Account/Account'
import Faq from '../pages/FAQ/Faq'
import EmailPage from '../pages/EmailPage/EmailPage'
import FeaturesPage from '../pages/FeaturesPage/FeaturesPage'
import ProgressPage from '../pages/ProgressPage/ProgressPage'
import SalePage from '../pages/SalePage/SalePage'
import Payment from './Payment/Payment'
import DiscountPage from '../pages/DiscountPage/DiscountPage'
import QuestionsPage from '../pages/QuestionsPage/QuestionsPage'

import './App.css'

import 'bootstrap/dist/css/bootstrap.min.css'

const App = () => {
  const currentPath = useLocation().pathname

  return (
    <div className='app'>
      {currentPath !== '/processing' &&
      currentPath !== '/sale/credit' &&
      currentPath !== '/processing-account' ? (
        <Header />
      ) : (
        ''
      )}
      <Routes>
        <Route exact path='/' element={<MainPage />} />
        <Route exact path='/account' element={<Account />} />
        <Route exact path='/faq' element={<Faq />} />
        <Route exact path='/email' element={<EmailPage />} />
        <Route exact path='/features' element={<FeaturesPage />} />
        <Route exact path='/processing' element={<ProgressPage />} />
        <Route exact path='/sale' element={<SalePage />} />
        <Route exact path='/sale/credit' element={<Payment />} />
        <Route exact path='/discount' element={<DiscountPage />} />
        <Route exact path='/question/1' element={<QuestionsPage />} />
        <Route exact path='/question/2' element={<QuestionsPage />} />
        <Route exact path='/question/3' element={<QuestionsPage />} />
        <Route
          exact
          path='/processing-account'
          element={<CreatingAccountPage />}
        />
      </Routes>
    </div>
  )
}

export default App
