import React from 'react'

const PhoneBlock = () => {
  return (
    <div className='phone-block'>
      <img
        className='phone-block__image phone-block__image-phone'
        src={require('./images/phone-frame.png')}
        alt='Phone frame'
      />
      <img
        className='phone-block__image phone-block__image-lock'
        src={require('./images/lock-screen.png')}
        alt='Lock Screen'
      />
      <div className='phone-block__lock-wrapper'>
        <img
          className='phone-block__lock'
          src={require('./images/lock.png')}
          alt='Phone lock'
        />
      </div>
      <span className='phone-block__text'>
        Create AI brought you super - animation, just take it!
      </span>
    </div>
  )
}

export default PhoneBlock
