import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const UploadBlock = () => {
  const [isAgreementChecked, setIsAgreementChecked] = useState(true)

  const toggleCheckedAgreement = () => {
    setIsAgreementChecked(!isAgreementChecked)
  }

  const navigate = useNavigate()

  const redirectToFeatures = () => {
    navigate('/features')
  }

  return (
    <div className='upload-block'>
      <div>
        <span className='upload-block__heading'>
          Just one photo and you’re a star
        </span>
        <div className='upload-block__rating'>
          <img
            className='upload-block__rating-image'
            src={require('./images/stars.png')}
            alt='Rating'
          />
          <span className='upload-block__rating-text'>
            Downloaded 1 million times
          </span>
        </div>
      </div>
      <div className='upload-block__button-block'>
        <label
          className={
            'upload-block__upload-photo-wrapper' +
            (!isAgreementChecked
              ? ' upload-block__upload-photo-wrapper--disabled'
              : '')
          }
        >
          <input
            disabled={!isAgreementChecked}
            className='upload-block__upload-photo-input'
            type='file'
            accept='.png,.jpg,.jpeg'
            onChange={redirectToFeatures}
          />
          <span className='upload-block__upload-button'>
            Upload a photo to start
          </span>
        </label>
        <a
          disabled={!isAgreementChecked}
          href='/question/1'
          className={
            'upload-block__skip-link' +
            (!isAgreementChecked ? ' upload-block__skip-link--disabled' : '')
          }
        >
          I'll try later
        </a>
      </div>
      <div className='upload-block__policy-agreement'>
        <input
          type='checkbox'
          onChange={toggleCheckedAgreement}
          checked={isAgreementChecked}
        />
        <span className='upload-block__policy-text'>
          By continuing I agree with{' '}
          <a className='upload-block__policy-link'>Terms of Service</a>,{' '}
          <a className='upload-block__policy-link'>Privacy policy</a>,{' '}
          <a className='upload-block__policy-link'>Money-Back Policy</a>,{' '}
          <a className='upload-block__policy-link'>Subscription Terms</a>,{' '}
          <a className='upload-block__policy-link'>
            Do Not Sell My Personal Information
          </a>
          .
        </span>
      </div>
    </div>
  )
}

export default UploadBlock
