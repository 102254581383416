import React from 'react'
import { Link } from 'react-router-dom'
import PhoneBlock from '../../components/PhoneBlock/PhoneBlock'
import Divider from '../../components/Shared/Divider/Divider'

const SalePage = () => {
  return (
    <main className='sale-page'>
      <div className='sale-page__textline'>
        <span className='sale-page__title'>
          Modernize yourself with Create AI!
        </span>
        <span className='sale-page__subtitle'>IOS, Android</span>
      </div>
      <div className='sale-page__controls'>
        <PhoneBlock />
        <div className='sale-page__bottom'>
          <Divider />
          <Link to='/sale/credit' className='sale-page__button'>
            Continue
          </Link>
        </div>
      </div>
    </main>
  )
}

export default SalePage
