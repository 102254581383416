import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { Link } from 'react-router-dom'

const LinkList = ({ hideDrawer }) => {
  return (
    <ul className='link-list'>
      <li className='link-list__item'>
        <Link className='link-list__link' onClick={hideDrawer} to='/account'>
          My Account
        </Link>
      </li>
      <li onClick={hideDrawer} className='link-list__item'>
        <Link className='link-list__link' onClick={hideDrawer} to='/faq'>
          FAQ
        </Link>
      </li>
      <li className='link-list__item'>
        <Accordion>
          <Accordion.Header>Terms & Policies</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to='/money-back-policy' className='link-list__link'>
                  Money-Back Policy
                </Link>
              </li>
              <li>
                <Link to='/privacy-policy' className='link-list__link'>
                  Privacy policy
                </Link>
              </li>
              <li>
                <Link to='/terms-of-use' className='link-list__link'>
                  Terms and Conditions of Use
                </Link>
              </li>
              <li>
                <Link to='/subscription-terms' className='link-list__link'>
                  Subscription Terms
                </Link>
              </li>
              <li>
                <Link to='/cookie-policy' className='link-list__link'>
                  Cookie policy
                </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion>
      </li>
    </ul>
  )
}

export default LinkList
