import React, { useState } from 'react'
import Logo from '../Shared/Logo/Logo'
import Offcanvas from 'react-bootstrap/Offcanvas'
import LinkList from './LinkList/LinkList'
import { useLocation, useNavigate } from 'react-router-dom'

import EmailIcon1 from './images/email-icon.svg'
import BackButton from './BackButton/BackButton'

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const currentPath = useLocation().pathname
  const navigate = useNavigate()

  const redirectToMain = () => {
    navigate(-1)
    hideDrawer()
  }

  const hideDrawer = () => setIsDrawerOpen(false)

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen)

  const offcanvasProps = { scroll: true, backdrop: false }

  return (
    <header className='header'>
      <div className='header__back'>
        {currentPath !== '/' || !isDrawerOpen ? (
          <BackButton redirectToMain={redirectToMain} />
        ) : (
          <div />
        )}
      </div>
      <Logo />
      <div
        className={
          'header__burger-icon' +
          (isDrawerOpen ? ' header__burger-icon--isActive' : '')
        }
        onClick={toggleDrawer}
      >
        <div className='header__burger-line header__burger-line-top' />
        <div className='header__burger-line header__burger-line-bottom' />
      </div>
      <Offcanvas
        className='header__offcanvas'
        show={isDrawerOpen}
        onHide={hideDrawer}
        placement='end'
        {...offcanvasProps}
      >
        <LinkList hideDrawer={hideDrawer} />
        <div className='header__info-box'>
          <img src={EmailIcon1} alt='Email icon' />
          <div className='header__info-text'>
            <span className='header__info-textline'>Contact Us</span>
            <a className='header__info-link' href='mailto:support@saucy.art'>
              support@saucy.art
            </a>
          </div>
        </div>
      </Offcanvas>
    </header>
  )
}

export default Header
