import React from 'react'

const Question = ({ image, text }) => {
  return (
    <div className='question'>
      <img className='question__icon' src={image} alt='Question icon' />
      <span className='question__text'>{text}</span>
    </div>
  )
}

export default Question
