import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Question from '../../components/Question/Question'

import MovieIcon from './images/movie-camera-min.png'
import PhotoIcon from './images/photo-camera-min.png'

import NerdIcon from './images/nerd.png'
import SmirkingIcon from './images/smirking.png'
import SunglassesIcon from './images/sunglasses.png'
import StarIcon from './images/star-struck.png'

const defaultAnswerObject = {
  answer1: null,
  answer2: null,
  answer3: null,
}

const ANSWERS = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
  AUTOMATISATION: 'AUTOMATISATION',
  CREATIVITY: 'CREATIVITY',
  EXPERIMENTS: 'EXPERIMENTS',
  CHANGES: 'CHANGES',
}

const BUBBLES = {
  INSTAGRAM: 'INSTAGRAM',
  YOUTUBE: 'YOUTUBE',
  TIKTOK: 'TIKTOK',
  SNAPCHAT: 'SNAPCHAT',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
}

const QuestionsPage = () => {
  const splittedPath = useLocation().pathname.split('/')
  const number = Number(splittedPath[splittedPath.length - 1])

  const [currentStep, setCurrentStep] = useState(Number(number))
  const [selectedAnswers, setSelectedAnswer] = useState(defaultAnswerObject)
  const [selectedBubbles, setSelectedBubbles] = useState([])
  const navigate = useNavigate()

  const selectAnswer = (answer, type) => {
    setSelectedAnswer({
      ...selectedAnswers,
      [answer]: type,
    })
    setCurrentStep(currentStep + 1)
    navigate(`/question/${currentStep + 1}`)
  }

  const selectBubble = (bubbleName) => {
    if (selectedBubbles.includes(bubbleName)) {
      setSelectedBubbles(
        selectedBubbles.filter((bubble) => bubble !== bubbleName)
      )
    } else {
      setSelectedBubbles([...selectedBubbles, bubbleName])
    }
  }
  console.log(selectedBubbles.includes(BUBBLES.INSTAGRAM))

  return (
    <main className='question-page'>
      <div className='question-page__wrapper'>
        <span className='question-page__question'>
          question {currentStep}/3
        </span>
        <span className='question-page__title'>
          {currentStep === 1
            ? 'What do you prefer more?'
            : currentStep === 2
            ? 'What do you love about photo editing?'
            : 'Where will you share your photos or videos the most?'}
        </span>
      </div>
      {currentStep === 1 ? (
        <ul className='question-page__questions-list'>
          <li onClick={() => selectAnswer('answer1', ANSWERS.PHOTO)}>
            <Question image={PhotoIcon} text='Deal with video/ GIF' />
          </li>
          <li onClick={() => selectAnswer('answer1', ANSWERS.VIDEO)}>
            <Question image={MovieIcon} text='Process photos' />
          </li>
        </ul>
      ) : currentStep === 2 ? (
        <ul className='question-page__questions-list'>
          <li onClick={() => selectAnswer('answer2', ANSWERS.AUTOMATISATION)}>
            <Question image={SunglassesIcon} text='Automatisation' />
          </li>
          <li onClick={() => selectAnswer('answer2', ANSWERS.CREATIVITY)}>
            <Question image={SmirkingIcon} text='Creativity' />
          </li>
          <li onClick={() => selectAnswer('answer2', ANSWERS.EXPERIMENTS)}>
            <Question image={NerdIcon} text='Experiments' />
          </li>
          <li onClick={() => selectAnswer('answer2', ANSWERS.CHANGES)}>
            <Question image={StarIcon} text='Changes' />
          </li>
        </ul>
      ) : (
        <div className='question-page__bubble-list-wrapper'>
          <ul className='question-page__bubble-list'>
            <li
              className={
                'question-page__bubble-wrapper' +
                (selectedBubbles.includes(BUBBLES.INSTAGRAM)
                  ? ' question-page__bubble-wrapper--selected'
                  : '')
              }
              onClick={() => selectBubble(BUBBLES.INSTAGRAM)}
            >
              <div className='question-page__bubble'>Instagram</div>
            </li>
            <li
              className={
                'question-page__bubble-wrapper' +
                (selectedBubbles.includes(BUBBLES.YOUTUBE)
                  ? ' question-page__bubble-wrapper--selected'
                  : '')
              }
              onClick={() => selectBubble(BUBBLES.YOUTUBE)}
            >
              <div className='question-page__bubble'> YouTube</div>
            </li>
            <li
              className={
                'question-page__bubble-wrapper' +
                (selectedBubbles.includes(BUBBLES.TIKTOK)
                  ? ' question-page__bubble-wrapper--selected'
                  : '')
              }
              onClick={() => selectBubble(BUBBLES.TIKTOK)}
            >
              <div className='question-page__bubble'>Tiktok</div>
            </li>
            <li
              className={
                'question-page__bubble-wrapper' +
                (selectedBubbles.includes(BUBBLES.SNAPCHAT)
                  ? ' question-page__bubble-wrapper--selected'
                  : '')
              }
              onClick={() => selectBubble(BUBBLES.SNAPCHAT)}
            >
              <div className='question-page__bubble'>Snapchat</div>
            </li>
            <li
              className={
                'question-page__bubble-wrapper' +
                (selectedBubbles.includes(BUBBLES.FACEBOOK)
                  ? ' question-page__bubble-wrapper--selected'
                  : '')
              }
              onClick={() => selectBubble(BUBBLES.FACEBOOK)}
            >
              <div className='question-page__bubble'>Facebook</div>
            </li>
            <li
              className={
                'question-page__bubble-wrapper' +
                (selectedBubbles.includes(BUBBLES.TWITTER)
                  ? ' question-page__bubble-wrapper--selected'
                  : '')
              }
              onClick={() => selectBubble(BUBBLES.TWITTER)}
            >
              <div className='question-page__bubble'>Twitter</div>
            </li>
          </ul>
        </div>
      )}
      {currentStep === 3 ? (
        <Link
          className={
            'question-page__button' +
            (selectedBubbles.length === 0
              ? ' question-page__button--disabled'
              : '')
          }
          to='/processing-account'
        >
          Continue
        </Link>
      ) : (
        <div />
      )}
    </main>
  )
}

export default QuestionsPage
