import React from 'react'
import Parser from 'html-react-parser'

import faqList from './faq.json'

const Faq = () => {
  return (
    <main className='faq'>
      <h2 className='faq__title'>FAQ</h2>
      <ul className='faq__list'>
        {faqList.map((faq) => {
          return (
            <li key={faq.title} className='faq__list-item'>
              <span className='faq__list-item-title'>{faq.title}</span>
              <p className='faq__list-item-text'>{Parser(faq.text)}</p>
            </li>
          )
        })}
      </ul>
    </main>
  )
}

export default Faq
