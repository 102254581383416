import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Confetti from 'react-confetti'

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}

const EmailPage = () => {
  const [isAgreementChecked, setIsAgreementChecked] = useState(true)
  const [isNewslettersChecked, setIsNewslettersChecked] = useState(true)
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)

  const { width, height } = useWindowSize()

  const toggleAgreement = () => {
    setIsAgreementChecked(!isAgreementChecked)
  }

  const toggleNewsletters = () => {
    setIsNewslettersChecked(!isNewslettersChecked)
  }

  const changeEmail = (event) => {
    setEmail(event.target.value)
    if (event.target.validity.valid) {
      setIsEmailValid(true)
    } else {
      setIsEmailValid(false)
    }
  }

  return (
    <main className='email'>
      <div />
      <form className='email__wrapper'>
        <h2 className='email__title'>Enter email</h2>
        <p className='email__text'>Photo's on your way</p>
        <input
          className='email__input'
          type='email'
          placeholder='somepost@gmail.com'
          onChange={changeEmail}
          value={email}
          required
        />
        <Link
          to='/sale'
          disabled={
            !isAgreementChecked || !isNewslettersChecked || !isEmailValid
          }
          className={
            'email__button' +
            (!isAgreementChecked || !isNewslettersChecked || !isEmailValid
              ? ' email__button--disabled'
              : '')
          }
        >
          Continue
        </Link>
      </form>
      <div className='email__checkboxes'>
        <div className='email__checkboxes-wrapper'>
          <input
            type='checkbox'
            onChange={toggleAgreement}
            checked={isAgreementChecked}
          />
          <span className='email__checkboxes-text'>
            By continuing I agree with{' '}
            <Link to='/terms-of-use' className='email__policy-link'>
              Terms of Service
            </Link>
            ,{' '}
            <Link to='/privacy-policy' className='email__policy-link'>
              Privacy policy
            </Link>
            ,{' '}
            <Link to='/money-back-policy' className='email__policy-link'>
              Money-Back Policy
            </Link>
            ,{' '}
            <Link to='/subscription-terms' className='email__policy-link'>
              Subscription Terms
            </Link>
            ,{' '}
            <Link to='/cookie-policy' className='email__policy-link'>
              Cookie policy
            </Link>
            ,{' '}
            <Link to='/do-not-sell-info' className='email__policy-link'>
              Do Not Sell My Personal Information
            </Link>
          </span>
        </div>
        <div>
          <input
            type='checkbox'
            onChange={toggleNewsletters}
            checked={isNewslettersChecked}
          />
          <span className='email__checkboxes-text'>
            I would like to receive updates about products, services, and
            special offers from Create AI via email.{' '}
            <Link to='/privacy-policy' className='email__policy-link'>
              Privacy policy
            </Link>
          </span>
        </div>
      </div>
      <Confetti recycle={false} width={width} height={height} />
    </main>
  )
}

export default EmailPage
