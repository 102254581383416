import React from 'react'

const Logo = () => {
  return (
    <div className='logo'>
      <img
        className='logo__image'
        src={require('./images/logo.png')}
        alt='Logo'
      />
      <span className='logo__text'>Create AI</span>
    </div>
  )
}

export default Logo
