import React from 'react'

import ArrowIcon from './images/left-arrow.svg'

const BackButton = ({ redirectToMain }) => {
  return (
    <button onClick={redirectToMain} className='back-button'>
      <img className='back-button__arrow' src={ArrowIcon} alt='Arrow' />
      <span className='back-button__text'>Back</span>
    </button>
  )
}

export default BackButton
