import React from 'react'
import PhotoSlider from '../../components/PhotoSlider/PhotoSlider'
import UploadBlock from '../../components/UploadBlock/UploadBlock'

const Main = () => {
  return (
    <main className='main'>
      <PhotoSlider />
      <UploadBlock />
    </main>
  )
}

export default Main
