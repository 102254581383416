import React, { useEffect, useState, useRef } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useNavigate } from 'react-router-dom'

function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const ProgressBlock = () => {
  const [progressPercents, setProgressPercents] = useState(0)
  const navigate = useNavigate()

  useInterval(
    () => setProgressPercents((prev) => prev + 1),
    progressPercents < 100 ? Math.floor(Math.random() * 100) : null
  )

  useEffect(() => {
    if (progressPercents === 100) {
      navigate('/email')
    }
  })

  return (
    <div className='progress-block'>
      <CircularProgressbar
        strokeWidth={6}
        className='progress-block__progressbar'
        value={progressPercents}
        text={`${progressPercents}%`}
      />
      <span className='progress-block__title'>
        Process your photo immediately...
      </span>
      <span className='progress-block__subtitle'>
        Don't close the page until the photo is complete.
      </span>
    </div>
  )
}

export default ProgressBlock
