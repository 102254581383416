import React from 'react'
import ProgressBlock from '../../components/ProgressBlock/ProgressBlock'

const ProgressPage = () => {
  return (
    <main className='progress-page'>
      <ProgressBlock />
    </main>
  )
}

export default ProgressPage
