import React from 'react'
import { Link } from 'react-router-dom'
import DiscountBlock from '../../components/DiscountBlock/DiscountBlock'
import MoneyBack from '../../components/MoneyBack/MoneyBack'

const DiscountPage = () => {
  return (
    <main className='discount-page'>
      <DiscountBlock />
      <img
        className='discount-page__logos'
        src={require('./images/footer-logos.png')}
        alt='Logos'
      />
      <div className='discount-page__link-list'>
        <a className='discount-page__link' href='mailto:support@saucy.art'>
          Contact Us
        </a>
        <Link className='discount-page__link' to='/privacy-policy'>
          Privacy policy
        </Link>
        <Link className='discount-page__link' to='/terms-of-use'>
          Terms of Service
        </Link>
      </div>
      <MoneyBack />
    </main>
  )
}

export default DiscountPage
