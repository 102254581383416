import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const CreatingAccountPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/email')
    }, 3000)
  })

  return (
    <main className='creating-account-page'>
      <span className='creating-account-page__title'>
        Creating your account...
      </span>
      <div className='creating-account-page__image-wrapper'>
        <span className='creating-account-page__image-title'>1 mln people</span>
        <span className='creating-account-page__image-subtitle'>
          Opt for Create AI, are you in?
        </span>
        <img
          className='creating-account-page__image'
          src={require('./images/people-winter.png')}
          alt='People Winter'
        />
      </div>
      <div />
    </main>
  )
}

export default CreatingAccountPage
