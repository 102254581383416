import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, A11y, Autoplay } from 'swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/effect-creative'

const PhotoSlider = () => {
  return (
    <div className='swiper-container'>
      <Swiper
        centeredSlides={true}
        className='photo-slider'
        modules={[Autoplay, Pagination, A11y]}
        slidesPerView={1}
        speed={1500}
        autoHeight={true}
        autoplay={{
          delay: 30000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        loop='true'
        breakpoints={{
          1024: {
            slidesPerView: 2,
          },
        }}
      >
        <SwiperSlide>
          <div className='photo-slider__img-block'>
            <img
              className='photo-slider__image'
              src={require('./images/slide1.png')}
              alt='slider1'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='photo-slider__img-block'>
            <img
              className='photo-slider__image'
              src={require('./images/slide2.png')}
              alt='slider2'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='photo-slider__img-block'>
            <img
              className='photo-slider__image'
              src={require('./images/slide3.png')}
              alt='slider3'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='photo-slider__img-block'>
            <img
              className='photo-slider__image'
              src={require('./images/slide4.png')}
              alt='slider4'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='photo-slider__img-block'>
            <img
              className='photo-slider__image'
              src={require('./images/slide5.png')}
              alt='slider5'
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default PhotoSlider
