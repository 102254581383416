import React from 'react'
import { Link } from 'react-router-dom'
import Divider from '../Shared/Divider/Divider'

const Payment = () => {
  return (
    <div className='payment'>
      <h2 className='payment__title'>Customize your photos with Create AI</h2>
      <div className='payment__price-wrapper'>
        <div className='payment__price-wrapper-discount'>save 50%</div>
        <div className='payment__price-content'>
          <span className='payment__price-content-main'>
            3-day trial for $0.99
          </span>
          <span className='payment__price-content-discount'>$19.99/month</span>
          <span className='payment__price-content-old-price'>$39.99/month</span>
        </div>
      </div>
      <ul className='payment__link-list'>
        <li className='payment__link-list-item'>
          <img
            className='payment__link-list-item-image'
            src={require('./images/checkbox.png')}
            alt='Checkmark'
          />
          <span className='payment__link-list-item-text'>
            Create AI gives 100% possibilities to make each photos amazing
          </span>
        </li>
        <li className='payment__link-list-item'>
          <img
            className='payment__link-list-item-image'
            src={require('./images/checkbox.png')}
            alt='Checkmark'
          />
          <Link
            className='payment__link-list-item-link'
            to='/money-back-policy'
          >
            Money-back guarantee
          </Link>
        </li>
      </ul>
      <p className='payment__description'>
        By continuing you agree that if you don't cancel at least 24 hours prior
        to the end of the <strong>3-days trial for $0.99</strong>, you will
        automatically be charged <strong>$19.99 every month</strong> until you
        cancel in settings. Learn more about cancellation and refund policy in{' '}
        <Link className='payment__description-link' to='/subscription-terms'>
          Subscription Terms
        </Link>
        .
      </p>
      <footer className='payment__footer'>
        <Divider className='payment__divider' />
        <Link className='payment__button' to='/discount'>
          Continue
        </Link>
      </footer>
    </div>
  )
}

export default Payment
