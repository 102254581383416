import React from 'react'
import { Link } from 'react-router-dom'

const MoneyBack = () => {
  return (
    <div className='money-back'>
      <div className='money-back__content'>
        <span className='money-back__content-title'>Money-back guarantee</span>
        <p className='money-back__content-text'>
          <strong>14-day money back guarantee</strong>, which entitles you to a
          full refund upon cancellation and emailing{' '}
          <a href='mailto:support@saucy.art'>support@saucy.art</a> to request a
          refund. Such refund requests must be made within the first 14 calendar
          days from your first date of payment. More about applicable
          limitations in our{' '}
          <Link className='money-back__content-link' to='/money-back-policy'>
            Money-back Guarantee Policy
          </Link>
          .
        </p>
      </div>
      <img
        className='money-back__image'
        src={require('./images/rosette.png')}
        alt='Rosette'
      />
    </div>
  )
}

export default MoneyBack
