import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import GiftIcon from './images/gift-icon.svg'

const PLANS = {
  MONTH1: 'MONTH1',
  MONTH3: 'MONTH3',
}

const DiscountBlock = () => {
  const [currentPlan, setCurrentPlan] = useState(null)

  const openWebsite = () => {
    window.open('https://solidgate/')
  }

  return (
    <div className='discount-block'>
      <img className='discount-block__image' src={GiftIcon} alt='Gift' />
      <div className='discount-block__headline'>
        <span className='discount-block__upper-title'>Start Your</span>
        <span className='discount-block__bottom-title'>
          3 Days Trial for $0.69
        </span>
      </div>
      <div className='discount-block__discount-list'>
        <div className='discount-block__discount-wrapper'>
          <div className='discount-block__discount-offer'>
            <div className='offer-plan__border__tags'>
              <span className='bestseller'>Bestseller</span>
              <span className='discount-off'>50% OFF</span>
            </div>
          </div>
          <div
            onClick={() => setCurrentPlan(PLANS.MONTH1)}
            className={
              'discount-block__discount-plan' +
              (currentPlan === PLANS.MONTH1
                ? ' discount-block__discount-plan--selected'
                : '')
            }
          >
            <div className='discount-block__discount-plan-left'>
              <span className='discount-block__discount-text-top'>
                1-MONTH <span>$0.66</span>
              </span>
              <p className='discount-block__small-text'>
                <strike>$19,99</strike> $9,99 every 1 month
              </p>
            </div>
            <div className='discount-block__discount-plan-right'>
              <img
                src={require('./images/price-bg.png')}
                alt='Price back'
                className='offer-plan__price__back off'
              />
              <div className='offer-plan__price__value'>
                <p className='offer-plan__price__value__currency off'>
                  $<span className='zero'>0</span>
                  <span className='price__right'>
                    <span>33</span>
                    <span className='price__right-every-day'>every day</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => setCurrentPlan(PLANS.MONTH3)}
            className={
              'discount-block__discount-plan discount-block__discount-plan-three-month' +
              (currentPlan === PLANS.MONTH3
                ? ' discount-block__discount-plan--selected'
                : '')
            }
          >
            <div className='discount-block__discount-plan-left'>
              <span className='discount-block__discount-text-top'>
                3-MONTH <span>$0.66</span>
              </span>
              <p className='discount-block__small-text'>$29,99 every 3 month</p>
            </div>
            <div className='discount-block__discount-plan-right'>
              <img
                src={require('./images/price-bg.png')}
                alt='Price back'
                className='offer-plan__price__back off'
              />
              <div className='offer-plan__price__value'>
                <p className='offer-plan__price__value__currency off'>
                  $<span className='zero'>0</span>
                  <span className='price__right'>
                    <span>33</span>
                    <span className='price__right-every-day'>every day</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={openWebsite}
        disabled={!currentPlan}
        className='discount-block__button'
      >
        Get my Discount
      </button>
      <p className='discount-block__description'>
        By continuing you agree that if you don't cancel at least 24 hours prior
        to the end of the <strong>3-days trial for $0.69</strong>, you will
        automatically be charged <strong>$9.99 every month</strong> until you
        cancel in settings. Learn more about cancellation and refund policy in{' '}
        <Link
          className='discount-block__description-link'
          to='subscription-terms'
        >
          Subscription Terms
        </Link>
        .
      </p>
    </div>
  )
}

export default DiscountBlock
